import React from 'react'
import Layout from '../layouts'
import CommonHero from '../components/commonhero/commonhero.js'
import WebriQForm from '@webriq/gatsby-webriq-form'

class ContactPage extends React.Component {
  render() {
    return (
      <Layout head={{ title: 'Brian Boals Has Colorado Homes Listed Online' }}>
        <CommonHero pageTitle="Contact Us" />
        <main className="bg-white">
          <div className="contact-section py-5">
            <div className="container">
              <div className="section-heading text-center">
                <h2>Get in Touch</h2>
                <h6>How to Find Us</h6>
              </div>
              <div className="section-content">
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <WebriQForm data-form-id="5c8748656d01764f7a54174e">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="Fist Name"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="Last Name"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="Email"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Phone (optional)</label>
                              <input
                                type="tel"
                                className="form-control"
                                name="Phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Subject (optional)</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Subject"
                          />
                        </div>
                        <div className="form-group">
                          <label>Message</label>
                          <textarea
                            className="form-control"
                            rows="4"
                            name="Message"
                            required
                          />
                        </div>
                        <div className="text-left">
                          <div className="webriq-recaptcha mb-4" />
                          <button className="alink hvr-shadow" type="submit">
                            Send Message
                          </button>
                        </div>
                      </WebriQForm>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="pt-5 text-center">
                      <div className="contact-detail pb-3">
                        <i className="mr-1 fa fa-map-marker" />
                        <p>
                          12265 Oracle Boulevard
                          <br />
                          Suite 200 - Colorado Springs, CO 80921
                        </p>
                      </div>
                      <div className="contact-detail pb-3">
                        <i className="mr-1 fa fa-phone" />
                        <p>
                          <a
                            href="tel:719-459-9955"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            (719) 459-9955
                          </a>
                        </p>
                      </div>
                      <div className="contact-detail pb-3">
                        <i className="mr-1 fa fa-envelope-open-o" />
                        <p>
                          <a
                            href="mailto:brianboals@gmail.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            brianboals@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default ContactPage
